<template>
  <div>
    <mdb-card cascade>
      <mdb-view
        class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
        cascade
      >
        <h3
          class="title-card-tec card-header-title color-primary-reversed"
          style="font-weight: 400"
        >
          Catégories Comptables
        </h3>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          @click="addOrEdit(null)"
        >
        </mdb-btn>
      </mdb-view>

      <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
        <mdb-row>
          <mdb-col sm="12" md="4" class="mb-n4">
            <mdb-select
              flipOnScroll
              outline
              class=""
              label="Nombre par page"
              @getValue="nbrPerPageSelected"
              v-model="dataPagination.pagination.perPageOptions"
            />
          </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n3">
            <mdb-input
              icon="search"
              outline
              type="text"
              v-model="dataPagination.searchQuery"
              placeholder="Rechercher"
              aria-label="Search"
            />
          </mdb-col>
        </mdb-row>

        <mdb-tbl responsiveMd >
          <mdb-tbl-head>
            <tr class="color-normal-reversed">
              <th v-for="column in data.columns" :key="column.name">
                {{ column.label }}
              </th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr
              class="tec-table-row color-normal-reversed"
              v-for="row in queriedData"
              :key="row.name"
            >
              <td class="tec-table-cell">
                <span
                  v-if="row.is_active === 1"
                  class="badge rounded-pill bg-success"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                <span
                  v-if="row.is_active === 0"
                  class="badge rounded-pill bg-danger"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                {{ row.category }}
              </td>
              <td class="d-flex justify-content-end">
                <el-dropdown trigger="click">
                  <span
                    class="el-dropdown-link"
                    style="cursor: pointer; font-weight: 500"
                  >
                    Actions<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                    <el-dropdown-item
                      @click.native="addOrEdit(row)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-edit"
                      >Modifier</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="deleteAccount(row.id)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-delete"
                      >Supprimer</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <div v-if="empty">
          <div
            class="d-flex justify-content-around align-items-center color-normal-reversed"
            style="padding: 50px"
          >
            <div>
              <h5>
                Commencez dès maintenant à ajouter vos premières catégories comptables !
              </h5>
              <p>
                Pour ajouter un nouveau utilisateur, cliquez sur le bouton
                <mdb-btn
                  class="z-depth-0"
                  style="cursor: inherit"
                  tag="a"
                  color="primary"
                  icon="plus"
                  size="sm"
                  small
                ></mdb-btn>
                en haut à votre droite.
              </p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <tec-pagination
            v-model="dataPagination.pagination.currentPage"
            :per-page="parseFloat(dataPagination.pagination.perPage)"
            :total="dataPagination.pagination.total"
          ></tec-pagination>
        </div>
      </mdb-card-body>
    </mdb-card>

    <mdb-modal
      centered
      size="md"
      direction="top"
      position="top"
      :show="modalCategory.show"
    >
      <form @submit.prevent="modalCategory.edit ? update() : save()">
        <mdb-modal-body class="modal-tec">
          <div class="modal-times-tec" @click="modalCategory.show = false">
            <i class="fas fa-times"></i>
          </div>
          <mdb-modal-title
            class="text-primary white-text"
            style="font-weight: 500 !important; font-size: 1.5rem !important"
            >{{ modalCategory.title }}</mdb-modal-title
          ><br />
          <fieldset>
            <mdb-row>
              <mdb-col sm="12" md="12">
                <mdb-input
                  :validation="$store.state.account_c.errors.category ? true : false"
                  :invalidFeedback="$store.state.account_c.errors.category"
                  v-model="form.category"
                  @change="categoryFormatter"
                  size="lg"
                  outline
                  label="Catégorie"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset>
            <legend>Avancé</legend>
            <mdb-row>
              <mdb-col sm="12" md="12" class="">
                <label for="">Etat de la catégorie?</label>
                <mdb-switch
                  v-model="form.is_active"
                  offLabel="Désactivé"
                  onLabel="Activé"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer>
          <div class="d-flex justify-content-end mb-3">
            <mdb-btn size="md" color="grey" @click="modalCategory.show = false"
              >Fermer</mdb-btn
            >
            <mdb-btn
              size="md"
              color="success"
              type="submit"
              :disabled="modalCategory.btn"
            >
              <span v-if="!modalCategory.btn">Sauvegarder</span>
              <span
                v-if="modalCategory.btn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="modalCategory.btn" class="pl-2">Loading...</span>
            </mdb-btn>
          </div>
        </mdb-modal-footer>
      </form>
    </mdb-modal>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/Pagination";
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbSwitch,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
  mdbModal,
  mdbModalTitle,
  mdbModalBody,
} from "mdbvue";
export default {
  name: "category",
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - CATEGORIES COMPTABLE",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbSwitch,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    TecPagination,
  },

  data() {
    return {
      dataPagination: {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [
            { text: "5", value: "5", selected: true },
            { text: "10", value: "10" },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["category"],
      },

      empty: false,
      loading: false,

      data: {
        columns: [
          {
            label: "Catégorie",
            field: "category",
            sort: true,
          },

          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },

      form: {
        id: "",
        category: "",
        is_active: true,
      },

      modalCategoryDetails: {
        show: false,
        edit: false,
        title: "Détails de la catégorie",
        content: {},
      },
      modalCategory: {
        show: false,
        edit: false,
        title: "Ajouter une catégorie comptable",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalCategory.title = "Modifier une categorie comptable";
        this.modalCategory.content = data;
        this.modalCategory.edit = true;
        this.formRest(data);
      } else {
        this.modalCategory.title = "Ajouter une categorie comptable";
        this.modalCategory.content = {};
        this.modalCategory.edit = false;
        this.cleanForm("account_c");
      }

      this.modalCategory.show = true;
    },

    cleanForm(type) {
      if (type === "account_c") {
        this.form.id = "";
        this.form.category = "";
        this.form.is_active = true;
      }
    },

    formRest(data) {
      this.form.id = data.id;
      this.form.category = data.category;
      this.form.is_active = data.is_active === 1 ? true : false;
    },

    async loadAccountingCategories() {
      this.$nprogress.start();
      this.loading != this.loading;
      await this.$store
        .dispatch("account_c/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading != this.loading;
          if (response.data.accountingCategories.length === 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.data.rows = response.data.accountingCategories;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading != this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("account_c/SET_CLEAN");
      this.modalCategory.btn = !this.modalCategory.btn;

      await this.$store
        .dispatch("account_c/save", this.form)
        .then((response) => {
          this.modalCategory.show = false;
          this.modalCategory.btn = !this.modalCategory.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("account_c");
          this.loadAccountingCategories();
        })
        .catch((error) => {
          this.modalCategory.btn = !this.modalCategory.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "account_c/SET_CATEGORY",
              error.response.data.errors.category
            );
          }

          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async update() {
      this.$store.commit("account_c/SET_CLEAN");
      this.modalCategory.btn = !this.modalCategory.btn;
      await this.$store
        .dispatch("account_c/update", this.form)
        .then((response) => {
          this.modalCategory.show = false;
          this.modalCategory.btn = !this.modalCategory.btn;
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("account_c");
          this.loadAccountingCategories();
        })
        .catch((error) => {
          this.modalCategory.btn = !this.modalCategory.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "account_c/SET_CATEGORY",
              error.response.data.errors.category
            );
          }
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteAccount(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("account_c/delete", id)
        .then((response) => {
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.loadAccountingCategories();
        })
        .catch((error) => {
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },

    categoryFormatter() {
      this.form.category = this.form.category.toUpperCase();
    },
  },

  created() {
    this.loadAccountingCategories();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
