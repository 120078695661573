<template>
    <div class="container-fluid">
        <mdb-row>
            <mdb-col sm="12" md="9" class=" mb-4 mx-0 mx-auto">
                <category></category>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import Category from './AccountCategory'
import {
    mdbRow,mdbCol,
} from 'mdbvue'
export default {
    components:{
        mdbRow,mdbCol,
        Category,
    },
    data(){
        return {
            //
        }
    }
}
</script>